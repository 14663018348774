.recall-admin-stats {
  display: flex;
  width: 100%;
  gap: 2rem;
}
.recall-admin-stats > div {
  transition: all 0.3s ease-in-out;
  width: 24%;
  border-radius: 23px;
  background: #89cff0;
  color: #fff;
  justify-content: space-between;
  padding: 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 1rem;
}

.recall-admin-stat-left-stat {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.recall-admin-stat-left p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 26px;
  font-weight: 500;
  line-height: 104.9%;
  letter-spacing: -0.264px;
}
.recall-admin-stat-left h1 {
  margin-top: 0.7rem;
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 104.9%;
  letter-spacing: -0.816px;
}
.recall-admin-stats > div .recall-admin-stat-left-stat img {
  transition: all 0.3s ease-in-out;
}
.recall-admin-stats > div:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.recall-admin-stats > div:hover .recall-admin-stat-left-stat img {
  transform: scale(1.1) rotate(-40deg);
}

.admin-page-all-pickups {
  transform: unset !important;
}
.recall-dashboard {
  width: 80%;
}
.recall-new-dashboard-grid,
.get-all-user-table {
  width: 100%;
}
.recall-dashboard{
    transform: scale(0.9);
    transform-origin: top left;
    margin-left: 3rem;
}
